import React from 'react'
import { Link } from 'react-router-dom'
// import Logo from '../../images/logo/UpDentalOfmanvel-logo_icon.svg'
import Logo from '../../images/logo/logo_white.svg'
import officeInfo from '../../api/addressInfo'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

export const handleEmailClick = () => {
    // Construct the mailto link
    const mailtoLink = `mailto:${officeInfo.email}`;

    // Open the default email client
    window.location.href = mailtoLink;
};
export const handlePhoneClick = () => {
    // Construct the tel link
    const telLink = `tel:${officeInfo.phone}`;

    // Navigate to the tel link
    window.location.href = telLink;
};
export const handleAddressClick = () => {
    const addressQuery = encodeURI(officeInfo.address); // Encode address for URL
       const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${addressQuery}`;
    window.open(mapsUrl, '_blank'); // Open Google Maps in a new tab
};

export const ShapeDividerWave = () => {
    return (
        <div class="custom-shape-divider-top-1719974039">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
        </svg>
    </div>
    )
}

export const FooterWave = () => {
    return (
        <div class="footer-bg-wave"></div>
    )
}

const Footer = (props) => {
    const schedule = officeInfo.schedule;
    return (
        <footer className={`wpo-site-footer ${props.footerClass}`}>
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="logo" />
                                </div>
                                <h6>FOLLOW US </h6>
                                <div className="social-widget">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/profile.php?id=61560639045049" target="_blank" rel="noopener noreferrer" onClick={ClickHandler}>
                                                <i className="fa fa-facebook" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="https://www.facebook.com/profile.php?id=61560639045049" target="_blank" rel="noopener noreferrer" onClick={ClickHandler}>
                                                <i className="fa fa-instagram" aria-hidden="true"></i>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Quick Links</h3>
                                </div>
                                <ul>
                                    {/* <li><Link onClick={ClickHandler} to="/about-us">About Us</Link></li> */}
                                    <li><Link onClick={ClickHandler} to="/faq">FAQs</Link></li>
                                    <li><Link onClick={ClickHandler} to="/case-gallery">Gallery</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/location">Our Location</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-contact-widget">
                                <div className="widget-title">
                                    <h3>Contact</h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li className='address-container' onClick={handleAddressClick}>
                                            <i className="fi flaticon-placeholder"></i>
                                            {officeInfo.address}
                                        </li>
                                        <li className='phone-container' onClick={handlePhoneClick} >
                                            <i className="fi flaticon-phone-call"></i> 
                                            {officeInfo.phone}
                                        </li>
                                        <li className='email-container' onClick={handleEmailClick}><i className="fi flaticon-email "></i>{officeInfo.email}</li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget hour-widget">
                                <div className="widget-title">
                                    <h3>Hours</h3>
                                </div>
                                <div > 
                                    <ul >
                                        {schedule.map((item, index) => (
                                            <li key={index} className="schedule-item">
                                                <span className="day">{item.day}</span> <span className="hour"> {item.hours}</span>
                                                {/* <strong>{item.day}</strong> {item.hours} */}
                                            </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            {/* <p className="copyright">Copyright &copy;<span>2025</span>
                                <span className="copyright-icon">|</span> All Rights Reserved.
                            </p> */}
                            <p className="copyright"> &copy;<span>2025 Up Dental Of Manvel</span>
                                
                            </p>
                             
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;