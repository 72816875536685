import React from "react";
import { Link } from 'react-router-dom'
import doctorProfile from '../../images/team/doctor-profile.png'
const AboutDoctorHero = () => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-hero-section section-padding" id="about-dr-hero-section">
            <div className="container">
                <div className="row align-items-center">
                    {/* WORD ON THE LEFT */}
                    <div className="col-lg-6 col-md-12 col-12" >
                        <div className="wpo-about-text" >
                            <span> Our Principal Dentist </span>
                            <div><h2>Tri Tran, D.M.D</h2></div>
                            <div >
                                <p id="dr-mission" > 
                                Our doctor's main interests are oral rehabilitation. His interests range from one single filling to one crown to full mouth rehabilitations that involve multiple crowns, and/or implants, and/or partial and full dentures. His versatile and wide scope of practice offers patients multiple alternatives to restore their chewing ability via treating existing teeth or replacing missing teeth. He is highly experienced in molar root canals and surgical extractions, including impacted wisdom teeth. Dr. Tran believes that prevention is better than reaction so focusing on early preventative consultations in very young patients/ kids population will help them need less dental treatments in their lifetime.
                                
                                </p>
                            </div>
                            {/* <div>
                                <Link to="/dr-tran" className="learn-more">
                                    Learn more about Dr. Tran &#8594; 
                                </Link>
                            </div> */}
                        </div>

                        {/* <div className="office-buttons">
                            <Link onClick={ClickHandler} className="theme-btn-accent" to="/all-team-members">Meet The Rest of The Team</Link>
                        </div> */}
                         <div className="office-buttons">
                            <Link onClick={ClickHandler} className="theme-btn-accent" to="/dr-tran">more about Dr. Tran</Link>
                        </div>
                    </div>

                    {/* PIC ON THE RIGHT */}
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img" id="about-dr-hero-img">
                                <img src={doctorProfile} alt="doctor" />
                            </div>
                         
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}

export default AboutDoctorHero;