// Case 1
import case1Img1 from '../images/case-gallery/case1/1.jpeg'
import case1Img2 from '../images/case-gallery/case1/2.jpeg'
import case1Img3 from '../images/case-gallery/case1/3.jpeg'
import case1Img4 from '../images/case-gallery/case1/4.jpeg'
import case1Img5 from '../images/case-gallery/case1/5.jpeg'
import case1Img6 from '../images/case-gallery/case1/6.jpeg'
import case1Img7 from '../images/case-gallery/case1/7.jpeg'
import case1Img8 from '../images/case-gallery/case1/8.jpeg'
import case1Img9 from '../images/case-gallery/case1/9.jpeg'
import case1Img10 from '../images/case-gallery/case1/10.jpeg'
import case1Img11 from '../images/case-gallery/case1/11.jpg'
// Case 2
import case2Img1 from '../images/case-gallery/case2/before.jpeg'
import case2Img2 from '../images/case-gallery/case2/after.jpeg'
// Case 3
import case3Img1 from '../images/case-gallery/case3/1.jpg'
import case3Img2 from '../images/case-gallery/case3/2.jpg'
import case3Img3 from '../images/case-gallery/case3/3.jpg'
import case3Img4 from '../images/case-gallery/case3/4.jpg'
// Case 4
import case4Img1 from '../images/case-gallery/case4/1.jpeg'
import case4Img2 from '../images/case-gallery/case4/2.jpeg'
import case4Img3 from '../images/case-gallery/case4/3.jpeg'
import case4Img4 from '../images/case-gallery/case4/4.jpeg'
import case4Img5 from '../images/case-gallery/case4/5.jpeg'
import case4Img6 from '../images/case-gallery/case4/6.jpeg'
import case4Img7 from '../images/case-gallery/case4/7.jpeg'
import case4Img8 from '../images/case-gallery/case4/8.jpeg'
// Case 5
import case5Img0 from '../images/case-gallery/case5/image0.jpeg'
import case5Img1 from '../images/case-gallery/case5/image1.jpeg'
import case5Img2 from '../images/case-gallery/case5/image2.jpeg'
import case5Img3 from '../images/case-gallery/case5/image3.jpeg'
import case5Img4 from '../images/case-gallery/case5/image4.png'
import case5Img5 from '../images/case-gallery/case5/5.jpeg'
import case5Img6 from '../images/case-gallery/case5/6.jpeg'
import case5Img7 from '../images/case-gallery/case5/7.jpeg'
import case5Img8 from '../images/case-gallery/case5/8.jpeg'
import case5Img9 from '../images/case-gallery/case5/9.jpeg'
import case5Img10 from '../images/case-gallery/case5/10.jpeg'
import case5Img11 from '../images/case-gallery/case5/11.jpeg'

// import img1 from '../images/case-gallery/teeth1.jpeg'
// import img2 from '../images/case-gallery/teeth2.jpg'
// import img3 from '../images/case-gallery/teeth3.jpg'
// export const caseData = [
//    {
//       caseNumber: 1, 
//       caseDescription:'Patient is missing the left front tooth due to a recent accident and want a replacement. Without adequate bone, an implant placement and bone graft are done together. After 3 months, the site shows nice healing. The end result shows good gum adaption to the new implant crown.',
//       items:[
//          {
//             image:  img1,
//             description: 'imply dummy text of the printing and typesetting'
//          },
//          {
//             image: img2,
//             description: 'with the release of Letraset sheets containing Lore'
//          },
//          {
//             image: img3,
//             description: 'NO description'
//          },
//       ]
//    },
//    {
//       caseNumber: 2,
//       caseDescription:'Third Molars, also called Wisdom Teeth, frequently cause pain and discomfort due to lack of spacing and difficulty to keep clean. Removal of these teeth will eliminate these issues for the patient. Under laughing nitrous gas and local numbing, patients usually function well after the procedure. Prescription can be given to better manage discomfort.',
//       items:[
//          {
//             image: img1,
//             description: 'Patient rehap'
//          },
//          {
//             image: img3,
//             description: 'NO description'
//          }
//       ]
//    },
//    {
//       caseNumber: 3, 
//       caseDescription:'What is the point of bone graft in dental treatments? Usually when patients have teeth that cannot be saved and need to be taken out, they are usually recommended bone graft when less than adequate bone is observed.\
//       Without bone graft, the bone ridge will shrink and make future implant placement more difficult or almost impossible and may need even more extensive bone grafting. With this bone grafting, the bone ridge will gain adequate width for near future implant placement.',
//       items:[
//          {
//             image: img1,
//             description: ' porro quisquam est qui dolorem ipsum quia do'
//          },
//          {
//             image: img2,
//             description: 'NO description'
//          }
//       ]
//    },
//    {
//       caseNumber: 4, 
//       caseDescription:'Can implant placement and extraction/removal of teeth be done together at the same time? Yes, but only for selected cases because there must be enough bone. In this selected case, after tooth removal, two implants are placed in simultaneously. By combining dental procedures cautiously and consciously, patient will have a more speedy recovery.',
//       items:[
//          {
//             image: img3,
//             description: ' porro quisquam est qui dolorem ipsum quia do'
//          },
//          {
//             image: img2,
//             description: 'NO description'
//          },
//          {
//             image: img2,
//             description: 'NO description'
//          }
//       ]
//    },
//    {
//       caseNumber: 5, 
//       caseDescription:'What is a root canal? A root canal, also called endodontic therapy, is a procedure done to remove biomaterial (blood vessels and nerve) inside the tooth\'s canal system. When bacterial origin invades this space (ie: large invasive cavity or tooth fracture), a root canal is needed.A root canal is by far the most effective and efficient treatment of choice to save teeth. The human body does not have rejection issues to its own teeth versus dental implants that have low rejection rate.',
//       items:[
//          {
//             image: img3,
//             description: ' porro quisquam est qui dolorem ipsum quia do'
//          },
//          {
//             image: img2,
//             description: 'NO description'
//          },
//          {
//             image: img2,
//             description: 'NO description'
//          }
//       ]
//    },

// ]

const caseGalleryData = [
   {
      caseNumber: 1, 
      caseDescription:'Patient is missing the left front tooth due to a recent accident and want a replacement. Without adequate bone, an implant placement and bone graft are done together. After 3 months, the site shows nice healing. The end result shows good gum adaption to the new implant crown.',
      images:[
         case1Img1, case1Img2, case1Img3, case1Img4, case1Img5, case1Img6, case1Img7, case1Img8, case1Img9, case1Img10, case1Img11
      ]
   },
   {
      caseNumber: 2,
      caseDescription:'Third Molars, also called Wisdom Teeth, frequently cause pain and discomfort due to lack of spacing and difficulty to keep clean. Removal of these teeth will eliminate these issues for the patient. Under laughing nitrous gas and local numbing, patients usually function well after the procedure. Prescription can be given to better manage discomfort.',
      images:[
        case2Img1, case2Img2,
      ]
   },
   {
      caseNumber: 3, 
      caseDescription:'What is the point of bone graft in dental treatments? Usually when patients have teeth that cannot be saved and need to be taken out, they are usually recommended bone graft when less than adequate bone is observed. Without bone graft, the bone ridge will shrink and make future implant placement more difficult or almost impossible and may need even more extensive bone grafting. With this bone grafting, the bone ridge will gain adequate width for near future implant placement.',
      images:[
        case3Img1, case3Img2, case3Img3, case3Img4
      ]
   },
   {
      caseNumber: 4, 
      caseDescription:'Can implant placement and extraction/removal of teeth be done together at the same time? Yes, but only for selected cases because there must be enough bone. In this selected case, after tooth removal, two implants are placed in simultaneously. By combining dental procedures cautiously and consciously, patient will have a more speedy recovery.',
      images:[
         case4Img1, case4Img2, case4Img3,case4Img4, case4Img5, case4Img6, case4Img6, case4Img7, case4Img8
      ]
   },
   {
      caseNumber: 5, 
      caseDescription:'What is a root canal? A root canal, also called endodontic therapy, is a procedure done to remove biomaterial (blood vessels and nerve) inside the tooth\'s canal system. When bacterial origin invades this space (ie: large invasive cavity or tooth fracture), a root canal is needed.A root canal is by far the most effective and efficient treatment of choice to save teeth. The human body does not have rejection issues to its own teeth versus dental implants that have low rejection rate.',
      images:[
        case5Img0, case5Img1, case5Img2, case5Img3, case5Img4, case5Img5, case5Img6, case5Img7, case5Img8, case5Img9, case5Img10, case5Img11, 
      ]
   },

]
export default caseGalleryData;