import React from 'react';
import { Link } from "react-router-dom";
import officeInfo from '../../api/addressInfo';
const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const AppoinmentSection = () => {

    return(
        // <section className="wpo-appoinment-section section-padding">
        <section className="wpo-appoinment-section-home section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                            <div className="wpo-section-title"> 
                                {/* <h2>Ready to book your appointment?</h2> */}
                                <h1>Ready to book your appointment?</h1>
                                {/* <p id="appointment-p">
                                    We look forward to seeing you at 
                                </p> */}
                            </div>
                      
                    </div>
                    {/* <div className="btn-container">
                        <Link onClick={ClickHandler} className="theme-btn-accent" to="/appoinment">Book Now</Link>
                    </div> */}
                     <div className="office-buttons col-lg-6 col-md-12 col-12" >
                        <Link onClick={ClickHandler} to="/appoinment" className="theme-btn">Book Appointment</Link>
                        <a href={`tel:${officeInfo.phone}`} className="theme-btn-accent-big">Call {officeInfo.phone}</a>
                    </div>
                </div>
            </div>
        </section>
     )
        
}

export default AppoinmentSection;
